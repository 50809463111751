import React from "react";

import loader from './images/icon-clock-a.svg';

function SendingLoader() {
    return (
        <div className="rcw-custom-response-message">
            <img
                src={loader}
                className="rcw-sending-loader"
                alt="sending"
            />
        </div>
    );
}

export default SendingLoader;