import React from "react";

function CustomResponseMessage(props) {
    const {message, id} = props;

    return (
        <div className="rcw-client" id={id}>
            <div className="rcw-message-text">
                <p dangerouslySetInnerHTML={{__html: message}}/>
            </div>
        </div>
    );
}

export default CustomResponseMessage;