import React, {useEffect, useState} from 'react';
import {
    Widget,
    toggleInputDisabled,
    renderCustomComponent,
    setBadgeCount,
    isWidgetOpened,
    deleteMessages
} from 'react-chat-widget-iss';
import io from 'socket.io-client';
import cookie from 'cookie';
import {v4 as uuidv4} from 'uuid';
import encode from 'nodejs-base64-encode';
import csx from 'classnames';

import QuickButtons from './QuickButtons';
import ButtonsDone from './ButtonsDone';
import SendingLoader from './SendingLoader';
import TypingLoader from './TypingLoader';
import CustomResponseMessage from './CustomResponseMessage';
import CustomUserResponseMessage from './CustomUserResponseMessage';

import 'react-chat-widget-iss/lib/styles.css';
import './App.scss';

import logo from './images/ava_chatbot.svg';

const cookieLifeTime = 31536000,
    socket = io("${CHATBOT_BACKEND}", {forceNew: true, transports: ['websocket']});
let registeredUser = cookie.parse(document.cookie).registeredUser,
    englishModeFromCookies = cookie.parse(document.cookie).englishMode,
    isWidgetOpen = false,
    buttonsCurrent = [],
    typeLoaderId = uuidv4(),
    sendLoaderId = uuidv4(),
    buttonsId = uuidv4(),
    inputIsDisabled = true,
    previousMessageType = null,
    labelEnterYourQuestion = 'Введите ваш вопрос...',
    labelInternetConnection = 'Идёт установка соединения...',
    labelAskAboutAnswer = 'Помог ли ответ',
    labelBotTitle = 'Чат-бот Онлайнинспектор',
    labelBotDescription = 'Работаю в тестовом режиме',
    labelChooseAnswerOption = 'Выберите вариант ответа выше',
    labelPleaseWait = 'Ждите ответа'

if (englishModeFromCookies) {
    labelEnterYourQuestion = 'Ask me something...'
    labelInternetConnection = 'Connection...'
    labelBotTitle = 'Online Inspector Bot'
    labelBotDescription = 'Work in test mode'
    labelChooseAnswerOption = 'Select some option above'
    labelPleaseWait = 'Please wait'
}
// 8e724ed5-d859-4c82-97a1-c4337589d107
// english0-a307-4db6-bf0e-67c5744b6177
if (!registeredUser) {
    registeredUser = uuidv4();
    if (englishModeFromCookies) {
        registeredUser = 'e0n1g2f' + registeredUser.substring(7, 50)
    }
    document.cookie = cookie.serialize('registeredUser', registeredUser, {maxAge: cookieLifeTime});
}

console.log('registeredUser ', registeredUser);

socket.emit('newClient', `{"uuid": "${registeredUser}"}`);

toggleInputDisabled();

document.addEventListener('click', e => {
    if (e.target.nodeName === 'A') {
        e.preventDefault();

        window.parent.postMessage({
            type: 'link click',
            link: {href: e.target.href}
        }, '*');
    } else if (e.target.closest('a')) {
        e.preventDefault();

        const target = e.target.closest('a');

        window.parent.postMessage({
            type: 'link click',
            link: {href: target.href}
        }, '*');
    }
});

function App() {
    const [senderPlaceHolder, setSenderPlaceHolder] = useState(labelInternetConnection),
        [serverIsLearning, setServerIsLearning] = useState(false);

    useEffect(() => {
        window.parent.postMessage('mounted', '*');  // Сообщаем родительскому блоку, что компонент замаунтился
        const receiveMessageFromParent = (event) => {
            const chatBotRoot = event.target.document.documentElement;

            switch (event.data.type) {
                case 'updateVisuallyImpairedStyles':  // Обновление фильтров в текущей сессии, а также приминение фильтров из прошлой сессии (Если в прошлую сессию версия для слабовидящих была активна)
                    Object.entries(event.data.payload).forEach(([key, value]) => {
                        chatBotRoot.setAttribute(`data-visuallyImpaired-${key}`, value.toString());
                    });
                    break;

                case 'resetVisuallyImpairedStyles':  // Отключение версии для слабовидящих
                    Object.keys(event.data.payload).forEach((key) => {
                        chatBotRoot.removeAttribute(`data-visuallyimpaired-${key}`);
                    });
                    break;

                default:  // Если сообщение не касается слабовидящих - ничего не делаем
                    break;
            }
        };

        window.addEventListener('message', receiveMessageFromParent);

        return () => {
            window.removeEventListener('message', receiveMessageFromParent);
        };
    }, []);

    useEffect(() => {
        socket.on('connect', () => {
            console.log('socket connected ', socket.id);
        });
        socket.on('disconnect', () => {
            console.log('socket disconnected');
        });
        socket.on('Register', data => {
            socket.emit('sendMessage', encodeUserMessage('/start'));
            setSenderPlaceHolder(labelEnterYourQuestion);
        });
        socket.on('messageReceive', data => {
            deleteMessages(1, sendLoaderId);
            renderCustomComponent(TypingLoader, {}, false, typeLoaderId);
        });
        socket.on('getMessage', data => {
            const dataParsed = JSON.parse(data),
                dataAnswer = dataParsed.Answer || {},
                dataAnswerBlockname = dataAnswer.Block_name || '',
                dataMessage = dataParsed.Message;
            let showResetQuestionButton = true;

            if (dataAnswerBlockname === 'Сервис недоступен') {
                setTimeout(() => {
                    deleteMessages(1, typeLoaderId);
                    setServerIsLearning(true);
                }, 500);
            } else {
                if (dataAnswerBlockname === labelAskAboutAnswer) {
                    showResetQuestionButton = false;
                }

                setTimeout(() => {
                    getNewMessage(dataMessage, false, showResetQuestionButton);
                }, 500);
            }
        });
        socket.on('getMessageUser', data => {
            const dataParsed = JSON.parse(data),
                dataMessage = dataParsed.Message;

            setTimeout(() => {
                getNewMessage(dataMessage, true);
            }, 500);
        });
        // eslint-disable-next-line
    }, []);

    setInterval(() => {
        const iwo = isWidgetOpened();

        if (isWidgetOpen !== iwo) {
            isWidgetOpen = iwo;
            window.parent.postMessage({
                type: 'chat widget toggle',
                isOpen: iwo
            }, '*');
        }
    }, 100);

    const encodeUserMessage = (text, isUserTyped = 0) => encode.encode(`{"Message": {"Text": "${text}","Buttons": null},"Id": "${registeredUser}", "UserTyped": "${isUserTyped}"}`, 'base64');

    const getNewMessage = (messageData, isUserMessage = false, showResetQuestionButton = true) => {
        const hasButtons = Boolean(messageData.Buttons);

        deleteMessages(1, typeLoaderId);
        setBadgeCount(1);

        if (messageData) {
            if (messageData.Text) {
                if (!hasButtons) {
                    setSenderPlaceHolder(labelEnterYourQuestion);

                    if (previousMessageType !== 'text' && inputIsDisabled) {
                        toggleInputDisabled();
                        inputIsDisabled = false;

                        const rcwInput = document.getElementsByClassName('rcw-new-message');
                        if (rcwInput && rcwInput.length) {
                            document.getElementsByClassName('rcw-new-message')[0].focus();
                        }
                    }
                }

                const messageId = uuidv4();
                previousMessageType = 'text';
                renderCustomComponent((isUserMessage ? CustomUserResponseMessage : CustomResponseMessage), {
                    message: messageData.Text,
                    profileAvatar: logo,
                    id: messageId
                }, false, messageId);
            }

            if (hasButtons) {
                setSenderPlaceHolder(labelChooseAnswerOption);

                if (previousMessageType === 'text' && !inputIsDisabled) {
                    toggleInputDisabled();
                    inputIsDisabled = true;
                }

                previousMessageType = 'buttons';
                setQuickButtons(messageData.Buttons, showResetQuestionButton);
            }
        }
    };

    const setQuickButtons = (buttons, showResetQuestionButton = true) => {
        buttonsCurrent = buttons;
        buttonsId = uuidv4();

        renderCustomComponent(QuickButtons, {
            buttons: buttons,
            showResetQuestionButton: showResetQuestionButton,
            chooseAnswer: handleQuickButtonClicked,
            clearQuestionSession: clearQuestionSession
        }, false, buttonsId);
    };

    const clearQuestionSession = () => {
        socket.emit('sendMessage', encodeUserMessage('/exit'));

        if (inputIsDisabled) {
            toggleInputDisabled();
            inputIsDisabled = false;
            const rcwInput = document.getElementsByClassName('rcw-new-message');
            if (rcwInput && rcwInput.length) {
                document.getElementsByClassName('rcw-new-message')[0].focus();
            }
        }

        setSenderPlaceHolder(labelEnterYourQuestion);
        deleteMessages(1, buttonsId);
    };

    const handleNewUserMessage = (newMessage, isButtonClicked) => {
        setSenderPlaceHolder(labelPleaseWait);
        typeLoaderId = uuidv4();
        sendLoaderId = uuidv4();

        if (!isButtonClicked) {
            toggleInputDisabled();
            inputIsDisabled = true;
            previousMessageType = null;
        }

        renderCustomComponent(SendingLoader, {}, false, sendLoaderId);

        console.log('message ', encodeUserMessage(newMessage, Number(!isButtonClicked)));
        socket.emit('sendMessage', encodeUserMessage(newMessage, Number(!isButtonClicked)));
    };

    const handleQuickButtonClicked = value => {
        renderCustomComponent(ButtonsDone, {
            buttons: buttonsCurrent,
            answerChosen: value.Id
        });
        deleteMessages(1, buttonsId);
        handleNewUserMessage(value.Label, true);
    };

    return (
        <div className={csx('App', {'server-is-learning': serverIsLearning})}>
            <Widget
                handleNewUserMessage={handleNewUserMessage}
                profileAvatar={logo}
                titleAvatar={logo}
                showTimeStamp={false}
                senderPlaceHolder={senderPlaceHolder}
                serverIsLearning={serverIsLearning}
                handleQuickButtonClicked={handleQuickButtonClicked}
                title={labelBotTitle}
                subtitle={labelBotDescription}
            />
        </div>
    );
}

export default App;