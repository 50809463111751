import React from "react";

function CustomResponseMessage(props) {
    const {message, profileAvatar, id} = props;

    return (
        <div className="rcw-custom-response-message" id={id}>
            <img
                src={profileAvatar}
                className="rcw-avatar"
                alt="profile"
            />
            <div className="rcw-response">
                <div className="rcw-message-text">
                    <p dangerouslySetInnerHTML={{__html: message}}/>
                </div>
            </div>
        </div>
    );
}

export default CustomResponseMessage;