import React, {useState} from "react";
import {v4 as uuidv4} from 'uuid';
import cookie from "cookie";

function QuickButtons(props) {
    const {buttons = [], chooseAnswer, clearQuestionSession, showResetQuestionButton} = props;
    const [isDoneLocal, setIsDoneLocal] = useState(false);
    const preparedButtons = (typeof (buttons) === 'object' && buttons.length) ? buttons : [];

    const englishModeFromCookies = cookie.parse(document.cookie).englishMode
    let buttonLabel = 'Задать другой вопрос'
    if (englishModeFromCookies) {
        buttonLabel = 'Ask another question'
    }

    return ((!isDoneLocal && Boolean(preparedButtons.length)) &&
        <div className="quick-buttons-container">
            <ul className="quick-buttons">
                {
                    preparedButtons.map(button => {
                        return <li className="quick-list-button" key={button.Id || uuidv4()}>
                            <button
                                className="quick-button"
                                onClick={() => {
                                    chooseAnswer(button);
                                    setIsDoneLocal(true);
                                }}
                                dangerouslySetInnerHTML={{__html: button.Label}}
                            />
                        </li>;
                    })
                }
                {
                    showResetQuestionButton &&
                    <li className="quick-list-button quick-list-button-reset">
                        <button
                            className="quick-button"
                            onClick={clearQuestionSession}
                        >
                            {buttonLabel}
                        </button>
                    </li>
                }
            </ul>
        </div>
    );
}

export default QuickButtons;
