import React from "react";
import csx from 'classnames';
import {v4 as uuidv4} from 'uuid';

function ButtonsDone(props) {
    const {buttons = [], answerChosen} = props;

    return <div className="buttons-done">
        <div className="quick-buttons-container">
            <ul className="quick-buttons">
                {
                    buttons.map(button => {
                        return (
                            <li
                                className={csx('quick-list-button', {active: answerChosen === button.Id})}
                                key={button.Id || uuidv4()}
                            >
                                <div className="quick-button"
                                     dangerouslySetInnerHTML={{__html: button.Label}}
                                />
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    </div>;
}

export default ButtonsDone;